.desktop {
  @apply flex;
}
.mobile {
  @apply hidden;
}
.section {
  padding-bottom: var(--gap-pad);
  background-color: black;
  .boxTitle {
    @apply flex justify-between items-center;
    padding-top: var(--gap-pad);
    .description {
      padding: 0 1rem;
      max-width: 37rem;
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
    .groupSocial {
      @apply gap-4;
    }
    .groupSocialDark {
      @apply gap-4 hidden;
    }
  }
  .box {
    @apply grid grid-cols-5 gap-6;
    padding-top: var(--pad-inner-2);
    .wrapperImage {
      position: relative;
      .icon {
        position: absolute;
        right: 6px;
        bottom: 6px;
      }
    }
    .wrapper {
      width: 100%;
      aspect-ratio: 1 / 1;
      .image {
        aspect-ratio: 1 / 1;
      }
    }
    &.mobile {
      @apply hidden;
    }
  }
  &.light {
    background-color: white;
    .boxTitle {
      h2 {
        color: var(--black-1);
      }
      .description {
        color: var(--black-1);
      }
      .groupSocial {
        @apply hidden;
      }
      .groupSocialDark {
        &.mobile {
          @apply hidden;
        }
        &.desktop {
          @apply flex;
        }
      }
    }
  }
}
@media (max-width: 1280px) {
}
@media (max-width: 1023px) {
}
@media (max-width: 576px) {
  .section {
    .boxTitle {
      @apply flex flex-col items-start;
      .groupSocial {
        padding-top: 1rem;
      }
      .description {
        padding-top: 2.625rem;
      }
    }
    .box {
      @apply grid grid-cols-2 gap-4 hidden;
      padding-top: 2rem;
      &.mobile {
        @apply grid;
      }
    }
    &.light {
      .boxTitle {
        h2 {
          color: var(--black-1);
        }
        .groupSocial {
          @apply hidden;
        }
        .groupSocialDark {
          &.mobile {
            @apply flex;
          }
          &.desktop {
            @apply hidden;
          }
        }
      }
    }
  }

  .desktop {
    @apply hidden;
  }
  .mobile {
    @apply flex;
  }
}
