.section {
  .boxTitle {
    @apply flex justify-between;
  }
  .box {
    @apply flex justify-between gap-10;
    max-width: 120rem;
    padding-top: var(--gap-pad);
    .colL {
      @apply flex flex-col gap-10 items-start;
      width: 50%;
    }
    .colR {
      @apply flex flex-col gap-10 items-end;
      width: 50%;
    }
  }
  .vertical {
    width: 80%;
  }
}

.item {
}
@media (max-width: 1280px) {
}
@media (max-width: 1023px) {
}
@media (max-width: 576px) {
  .section {
    .boxTitle {
      .btnLabel {
        display: none;
      }
    }
    .box {
      @apply flex flex-col;
      .colL {
        width: 100%;
      }
      .colR {
        width: 100%;
      }
    }
    .vertical {
      width: 100%;
    }
  }
}
