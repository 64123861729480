$gapPadding: 4.5rem;
.section {
  padding-top: $gapPadding;
  padding-bottom: $gapPadding;
  .video {
    @apply flex justify-center mx-auto;
    width: 100%;
    max-width: 1280px;
    aspect-ratio: 16 / 9;
  }
  .logo {
    @apply flex justify-center;
    .wrapperImage {
      width: 174px;
      height: 174px;
      aspect-ratio: 1 / 1;
      .image {
        aspect-ratio: 1 / 1;
      }
    }
  }
  .box {
    @apply relative;
    padding-top: $gapPadding;
    .content {
      padding-top: $gapPadding;
      .title {
      }
      .subTitle {
        padding-top: 1.5rem;
      }
    }

    .wrapperBg {
      @apply absolute;
      width: 100%;
      height: 100%;
      .imageBg {
        width: 100%;
        // aspect-ratio: 1440 / 675;
        object-fit: cover;
      }
    }
    .swiper {
      padding-top: 3rem;
      height: 317px;
      box-sizing: content-box;
      .slideItem {
        @apply relative flex justify-center;
        &:hover {
          .slideTitle {
            &:after {
              background: rgba(243, 164, 133, 0.5);
            }
            .journeyTitle {
              top: 40%;
              opacity: 1;
            }
            .journeyContent {
              top: 55%;
              opacity: 1;
            }
          }
        }
        .slideTitle {
          @apply absolute flex justify-center items-center z-10 w-full h-full inset-0 text-center mx-auto;
          aspect-ratio: 251 / 317;
          max-width: 251px;
          max-height: 317px;
          &:after {
            width: 100%;
            height: 100%;
            position: absolute;
            content: "";
            transition: all 0.6s cubic-bezier(0.47, 0, 0.745, 0.715);
            -webkit-transition: all 0.6s cubic-bezier(0.47, 0, 0.745, 0.715);
          }
          .journeyTitle {
            @apply absolute flex justify-center items-center z-10 w-full h-full inset-0 text-center mx-auto;
            top: 50%;
            left: 50%;
            opacity: 1;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transition: all 0.6s cubic-bezier(0.47, 0, 0.745, 0.715);
            -webkit-transition: all 0.6s cubic-bezier(0.47, 0, 0.745, 0.715);
            color: #ffffff;
          }
          .journeyContent {
            @apply absolute flex justify-center items-center z-10 w-full h-full inset-0 text-center mx-auto;
            top: 50%;
            left: 50%;
            opacity: 0;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transition: all 0.6s cubic-bezier(0.47, 0, 0.745, 0.715);
            -webkit-transition: all 0.6s cubic-bezier(0.47, 0, 0.745, 0.715);
            color: #ffffff;
          }
        }
        .wrapperImage {
          width: 100%;
          height: 100%;
          max-width: 251px;
          max-height: 317px;
          aspect-ratio: 251 / 317;
          .image {
            aspect-ratio: 251 / 317;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
}
@media (max-width: 1023px) {
}
@media (max-width: 576px) {
  .section {
    padding-top: 0rem;
    .logo {
      .wrapperImage {
        width: 110px;
        height: 110px;
      }
    }
  }
}
