.desktop {
  display: block !important;
}
.mobile {
  display: none !important;
}
.swiper {
  @apply relative w-full h-screen;
  display: block;
  .slideItem {
    .wrapperImage {
      height: 100vh;
      width: 100vw;
      .image {
        height: 100vh;
      }
    }
    // .slideTitle {
    //   @apply absolute z-10 w-full h-full left-0 right-0  mx-auto flex justify-end items-end;
    //   max-width: var(--innerWidth);
    //   padding: 0 var(--pad-inner-large);
    //   padding-bottom: 7.25rem;

    //   .messageBox {
    //     @apply relative;
    //     width: 34.8%;
    //     max-width: 502px;
    //     .wrapper {
    //       width: 100%;
    //       aspect-ratio: 502 / 346;
    //     }
    //     .wrapper1 {
    //       width: 100%;
    //       aspect-ratio: 502 / 346;
    //       .message1 {
    //       }
    //     }
    //     .wrapper2 {
    //       width: 100%;
    //       aspect-ratio: 502 / 346;
    //       .message2 {
    //       }
    //     }
    //     .wrapper3 {
    //       width: 100%;
    //       aspect-ratio: 410 / 433;
    //       .message3 {
    //       }
    //     }
    //   }
    // }
    .wrapperVideo {
      height: 100vh;
      width: 100vw;
      .video {
        height: 100%;
        width: 100%;
        object-fit: cover;
        aspect-ratio: 1440 /900;
      }
    }
  }
}

@media (max-width: 1280px) {
}
@media (max-width: 1023px) {
}
@media (max-width: 576px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
  .swiper {
    .slideItem {
      .wrapperImage {
        aspect-ratio: 390 / 718;
      }
      .wrapperVideo {
        .video {
          aspect-ratio: 390 / 718;
        }
      }
    }
  }
}
