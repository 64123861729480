$gapPadding: 72px;
.section {
  background-color: var(--bg-black-color);
  padding-top: $gapPadding;
  padding-bottom: $gapPadding;
  .headBox {
    @apply flex;
  }
  .imageBox {
    padding-top: 32px;
    .wrapperImage {
      padding-top: 32px;
      width: 100%;
      //   max-height: 470px;
      aspect-ratio: 1080 / 470;
      .image {
        aspect-ratio: 1080 / 470;
      }
    }
  }
  .content {
    @apply text-center;
    padding-top: 32px;
  }
  .button {
    padding-top: 32px;
  }
}

@media (max-width: 1280px) {
}
@media (max-width: 1023px) {
}
@media (max-width: 576px) {
  .section {
    padding-bottom: 0px;
    .headBox {
      @apply flex-col items-center text-center;
      .title {
        @apply mb-8;
      }
    }
    .content {
      @apply text-center;
    }
  }
}
