$gapPadding: var(--gap-pad);
.desktop {
  @apply block;
}
.mobile {
  @apply hidden;
}
.section {
  .sectionTitle {
    padding-top: $gapPadding;
  }
  .box {
    @apply relative;
    padding-bottom: $gapPadding;
    .content {
      padding-top: $gapPadding;
      @apply flex justify-between gap-6;
      .title {
      }
      .description {
        max-width: 636px;
        flex: 1;
      }
      .button {
      }
    }
    .imageBox {
      padding-top: $gapPadding;
      .wrapperImage {
        //   @apply absolute;
        width: 100%;
        height: 100%;
        aspect-ratio: 1080 / 560;
        .image {
          width: 100%;
          aspect-ratio: 1080 / 560;
          object-fit: cover;
        }
      }
    }
  }
}

@media (max-width: 1280px) {
}
@media (max-width: 1023px) {
}
@media (max-width: 576px) {
  .section {
    .box {
      @apply flex flex-col-reverse;
      .content {
        @apply flex flex-col gap-0;
        .button,
        .description {
          padding-top: 2rem;
        }
      }
    }
  }
  .desktop {
    @apply hidden;
  }
  .mobile {
    @apply block;
  }
}
