.section {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: black;
  .bg {
    @apply absolute inset-0;
    width: 100vw;
    height: 100vh;
    aspect-ratio: 1440 / 900;
  }

  .logo {
    @apply absolute z-10 inset-0 m-auto;
    width: 9.375rem;
    aspect-ratio: 1 / 1;
  }
}
